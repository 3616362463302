import React from "react"

import { Container, Row, Col } from 'reactstrap'
import Link from '../components/link'
import Button from '../components/btn'
import Layout from "../components/layout"
import SEO from "../components/seo"
import Form from '../components/form'
import Slider from '../components/slider'
import Box from '../components/box'
import Hr from '../components/hr'
import { FaHome, FaDesktop, FaMobileAlt, FaChartLine, FaBong, FaChalkboardTeacher } from 'react-icons/fa';
import {IoIosArrowDroprightCircle} from 'react-icons/io';
import { GoDashboard } from 'react-icons/go';
import Clients from '../components/clients'
import styled from 'styled-components'
import PageTitle from '../components/page-title'
import Img from 'gatsby-image/withIEPolyfill'
import { FaCode } from 'react-icons/fa'

let StyledImg = styled(props => <Img {...props}/>)`
  perspective: 1500px;
  perspective-origin: left center;
  overflow: visible !important;
  picture, img {
    transform: rotateY(-35deg) rotateX(15deg);
    box-shadow: 25px 60px 125px -25px rgba(80,102,144,.1), 16px 40px 75px -40px rgba(0,0,0,.2);
    border-radius: .625rem;
    transition: 1s !important;
    &:hover {
      transform: rotateY(-30deg) rotateX(15deg);
    }
  }
`

let StyledBackground = styled.div`
  background: linear-gradient(to bottom,#f9fbfd 0,#fff 100%);
`

const Background = styled.div`
  background: #dd45d3;
  color: white;
  padding: 5rem 0;
  clip-path: polygon(0 0, 100% 0, 100% 85%, 0% 100%);
  text-align: center;
`

let Service = ({title, Icon = IoIosArrowDroprightCircle, link}) => (
  <div className="d-flex mb-4">
    <div><IoIosArrowDroprightCircle size={30} className="text-primary"/></div>
    <div className="ml-3">
      <a href={link} target="_blank"><h4>{title}</h4></a>
    </div>
  </div>
)

let Tool = ({title, icon, content}) => (
  <div className="d-flex mb-5">
    <img src={icon} class="text-primary" width="75px"/>
    <div className="ml-3">
      <h4>{title}</h4>
      <p className="m-0 text-muted">{content}</p>
    </div>
  </div>
)

export default () => (
  <Layout>
    <SEO title="About Empathy Limited | Empathy | Data agency" description="Empathy help business make better digital presence with data. We believe data is the origin and common ground where customers and brands connect. Contact us to know how you can empathize with your customers with data." keywords="web analytics, app analytics, Google Analytics, Adobe Analytics, Google Tag Manager, Firebase, AppsFlyer, tracking implementation, reporting, analysis, AB testing"/>
    <Background>
      <h2>"Empathy is the ultimate form of customer insight."</h2>
      <p>Don Peppers - author, speaker, founding partner of CX Speakers</p>
    </Background>
    <Container className="pt-4">
      <div className="text-center">
        <h1>About Us</h1>
        <p class="text-muted">We believe in data, not HiPPOs (Highest Paid Persons' Opinion)</p>
        <p>We believe <strong>DATA</strong> is the origin and the common ground where customers and brands connect and understand each other.</p>
        <p>Founded in 2019, we are passionate to bring data analytics to brands from different industries to help them understand their customers better.</p>
        <p>We offer the following services to help brands empathize with their customers with data:</p>
      </div>
    </Container>
    <Container className="pt-4">
      <Row>
        <Col>
          <Service title="Website &amp; app tracking" link="/website-and-app-tracking"/>
          <Service title="Website audit &amp; analysis" link="/website-audit-and-analysis"/>
          <Service title="Interactive dashboard creation" link="/dashboard"/>
        </Col>
        <Col>
          <Service title="Website optimization" link="/website-optimization"/>
          <Service title="Corporate training" link="/corporate-training"/>
        </Col>
      </Row>  
    </Container>   
    <Container className="pt-4">
      <div className="text-center">
        <p>If you are ready to join us on the journey to empathize with your customers, don't hestitate to reach out to us!</p>
        <Button to="mailto:hellow@empathystrategy.com" className="btn btn-primary btn-lg mt-4 mb-5">Email Us</Button>
      </div>
    </Container>
    <StyledBackground>
      <div className="py-5">
        <Container>
          <Row className="d-flex justify-content-center">
            <Col md={8}>
              <Box style={{textAlign: 'left'}}>
                <h3 className="text-center">Contact Us</h3>
                <Hr/>
                <Form/>
              </Box>
            </Col>
          </Row>
        </Container>
      </div>
    </StyledBackground>
  </Layout>
)
